.top-controlbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  position: absolute;
  width: calc(100% - 29px);
  height: 55px;
  left: 29px;
  top: 45px;
  font-family: Roboto;
  &-left, &-right {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;
  }

  &-right {
    justify-content: flex-end;
    margin-right: 37px;
    margin-top: 23px;

  }
  .player-back-icon {
    font-weight: bold;
    font-size: 52px;
    width: 52px;

    cursor: pointer;
    color: white;

    &.active {
      color: #D10A14;
    }
    &:hover {
      color: #D10A14;
    }
  }
  .player-season-icon {
    font-weight: bold;
    font-size: 44px;
    cursor: pointer;
    color: white;

    &.active {
      color: #D10A14;
      text-shadow: 10px 10px 10px #FFFFFF;
    }
    &:hover {
      color: #D10A14;
      text-shadow: 10px 10px 10px #FFFFFF;
    }
  }
  .player-title {
    font-family: Arial;
    font-size: 32px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    line-height: 32px;
    text-align: right;
    z-index: 1;
  }

  //////////////////////////////// live

  .player-live-back-icon {
    font-weight: bold;
    font-size: 52px;
    width: 52px;

    cursor: pointer;
    color: white;
    margin-right: 25px;

    &.active {
      color: #D10A14;
    }
    &:hover {
      color: #D10A14;
    }
  }

  .player-icon-wrap{
    position: relative;
    p {
      position: absolute;
      font-size: 22px;
      color: #cb2a32;
      text-decoration: none solid rgb(203, 42, 50);
      text-align: center;
      width: auto;
      text-align: center;
      white-space: nowrap;
      margin-top: 10px;

      left: 50%;
      text-align: center;
      transform: translate(-50%, 0px);
    }
    &.player-icon-wrap-vod{
      margin-right: 20px;
    }
  } 

  .player-live-schedule-icon, .player-live-info-icon {
    cursor: pointer;
    font-weight: bold;
    font-size: 35px;
    cursor: pointer;
    color: white;
    margin-right: 25px;

    &.active {
      color: #D10A14;
    }
    &:hover {
      color: #D10A14;
    }
  }

  .player-live-info-icon {
    font-size: 44px;
  }

  .player-live-icon-warp {
    min-width: 100px;
    min-height: 39px;
    border-radius: 39px;
    background-color: #FC3831;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    padding: 9px 13px;
  
    .player-live-icon-white-dot {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #FFFFFF;
      margin-right: 9px;
    }
  
    .player-live-icon-text {
      font-size: 13px;
      color: #ffffff;
      text-decoration: none solid rgb(255, 255, 255);
      line-height: 21px;
      text-align: right
    }
  }

  .player-live-title {
    font-size: 34px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    line-height: 32px;
    text-align: right;
  }
}