.live-event-before{
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #1c1c1c;
  font-family: Roboto;

  .live-event-back{
    position: absolute;
    top: 46px;
    left: 29px;
    display: grid;
    .back {
      font-size: 52px;
      width:  52px;
      color: #fff;
    }
  }

  .title {
    font-size: 60px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 8px;
    font-weight: bold;
  }
  .content-name {
    // text-transform: uppercase;
    font-size: 40px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    margin-bottom: 28px;
  }
  .txt {
    font-size: 45px;
    color: #b8b8b8;
    text-decoration: none solid rgb(184, 184, 184);
    text-align: center;
    margin-bottom: 8px;
  }
  .time {
    font-size: 50px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
  }
}