.player-col {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

#debug-shakaaaa{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background: red;
  opacity: 0.7;
}
.player-next-episode-icon {
  position: absolute;
  top: 45px;
  left: 180px;
  font-weight: bold;
  font-size: 30px;
  cursor: pointer;
  color: white;
  z-index: 10;
  &.active {
    color: #D10A14;
    text-shadow: 10px 10px 10px #FFFFFF;
  }
  &:hover {
    color: #D10A14;
    text-shadow: 10px 10px 10px #FFFFFF;
  }
}


.player-season {
  cursor: pointer;
  position: absolute;
  bottom: 4%;
  left: 70px;
  z-index: 10;
  padding: 5px 20px;
  font-weight: bold;
  font-size: 25px;
  color: white;
  &.active {
    background-color: #D10A14;
  }
  &:hover {
    background-color: #D10A14;
  }
}

.player {
  .toast {
    z-index: 500;
    width: 500px !important;
    position: absolute;
    bottom: 5%;
    right: 0;
    padding: 5px 10px;
    background-color: grey;
    color: white;
    font-size: 20px;
    font-weight: bold;
  }
  
  .player-loading{
    z-index: 9;
  }
  .video-js {
    .vjs-volume-panel {
      display: none;
    }
    .vjs-picture-in-picture-control {
      display: none;
    }
    .vjs-fullscreen-control {
      display: none;
    }
    .vjs-live-control {
      display: none;
    }
    .vjs-seek-to-live-control {
      display: none;
    }
    .vjs-big-play-button {
      display: none;
    }
    .vjs-playback-rate {
      display: none;
    }
    .vjs-chapters-button {
      display: none;
    }
    .vjs-descriptions-button {
      display: none;
    }
    .vjs-subs-caps-button {
      display: none;
    }
    .vjs-audio-button {
      display: none;
    }
    .vjs-progress-control {
      .vjs-progress-holder {
        .vjs-play-progress {
          background-color: #D10A14;
        }
        .vjs-play-progress:before {
          display: none;
        }
      }
    }

    .vjs-slider {
      height: 10px;
      border-radius: 10px;
      overflow: hidden;
    }

    .vjs-control-bar {
      position: absolute;
      bottom: 12%;
      left: 0;
      right: 0;
      margin: 0 61px 0 46px;
      width: calc(100% - 61px - 46px);
      z-index: 10;
      touch-action: none;
      align-items: center;
      background-color: transparent;
      display: flex;
      .vjs-volume-panel {
        display: none;
      }
      .vjs-prev-eps {
        order: 0;
      }
      .vjs-seek-prev  {
        order:1
      }
      .vjs-play-control{
        order: 2;
      }
      .vjs-seek-next {
        order:3
      }
      .vjs-next-eps {
        order: 4;
        span{
          position: absolute;
          bottom: -35px;
          left: 50%;
          white-space: nowrap;
          text-align: center;
          font-size: 22px;
          transform: translate(-50%, 0px);
          visibility: hidden;
          opacity: 0;
        }
      }
      .vjs-current-time{
        order: 5;
      }
      .vjs-progress-control{
        order: 6;
      }
      .vjs-remaining-time{
        order: 7;
      }
      &.visibility-hidden {
        visibility: hidden!important;
        opacity: 0!important;
        transition: visibility 0s linear 300ms, opacity 300ms!important;
      }
      
      &.visibility-visible {
        visibility: visible!important;
        opacity: 1!important;
        transition: visibility 0s linear 0s, opacity 300ms!important;
      }
      .vjs-prev-eps, .vjs-next-eps, .vjs-seek-next, .vjs-seek-prev {
        span{
          position: absolute;
          bottom: -35px;
          left: 50%;
          white-space: nowrap;
          text-align: center;
          font-size: 22px;
          transform: translate(-50%, 0px);
          visibility: hidden;
          opacity: 0;
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 50%;
          height: 50%;
          transform: translate(50%, 50%);
        }
      }
      .vjs-seek-prev {
        img {
          transform: translate(50%, 50%) rotateZ(180deg);;
        }
        span {
          transform: translate(-18%, 0%);
          left: 0px;
        }
        &.first {
          span {
            transform: translate(0%, 0%);
            left: 0px;
          }
        }
      }
      .vjs-prev-eps {
        span {
          transform: translate(0%, 0%);
          left: 0px;
        }
      }
    }

    .vjs-play-control, .vjs-next-eps, .vjs-prev-eps, .vjs-seek-next, .vjs-seek-prev {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background-color:#000000;
      margin-right: 20px;
      &::after{
        content:attr(data-content);
        position: absolute;
        bottom: -40px;
        left: 50%;
        white-space: nowrap;
        text-align: center;
        font-size: 22px;
        transform: translate(-50%, 0px);
        visibility: hidden;
        opacity: 0;
      }
      .vjs-icon-placeholder{
        font-size: 20px;
        color: #ffffff;
      }
      &.active {
        background-color: #D10A14;
        span, &::after{
          visibility: visible;
          opacity: 1;
        }
      }

      .vjs-icon-placeholder{
        &::before {
          font-size: 22px;
          transform: translate(0%, 9%);
        }
      }
    }
    .vjs-current-time, .vjs-remaining-time {
      display: flex;
      align-items: center;

      .vjs-current-time-display, .vjs-remaining-time-display {
        font-size: 19px;
      }
    }
  }
  .vjs-tech {
    pointer-events: none;
  }
}

.vjs-stream-error{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  padding: 5rem;
  text-align: center;
  .vjs-modal-dialog-content{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    text-align: center;
  }
}

.vjs-marker{
  position: absolute;
  width: 5px;
  background: yellow;
  height: 10px;
}