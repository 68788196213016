.player-live-col {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.player-live-title-icon {
  position: absolute;
  top: 45px;
  left: 190px;
  z-index: 10;
  border-radius: 50px;
}

.player-live-schedule {
  cursor: pointer;
  position: absolute;
  bottom: 100px;
  left: 70px;
  z-index: 10;
  padding: 5px 20px;
  font-weight: bold;
  font-size: 25px;
  color: white;
  &.active {
    background-color: #D10A14;
  }
  &:hover {
    background-color: #D10A14;
  }
}


.player-live {
  .player-live-blackout-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 9;

    .player-live-blackout-logo {
      width: 30vw;
      margin-left: 140px;
      margin-bottom: 50px;
    }

    .player-live-blackout-title {
      font-size: 50px;
      color: #FFFFFF;
      margin-bottom: 5px;
    }

    .player-live-blackout-message {
      font-size: 30px;
      color: #999999;
    }
  }

  .toast {
    z-index: 500;
    width: 500px !important;
    position: absolute;
    bottom: 5%;
    right: 0;
    padding: 5px 10px;
    background-color: grey;
    color: white;
    font-size: 20px;
    font-weight: bold;
  }
  
  .vjs-tech {
    pointer-events: none;
  }
  
  .video-js {
    width: 100%;
    height: 100vh;
    .vjs-play-control {
      display: none;
    }
    .vjs-mute-control {
      display: none;
    }
    .vjs-volume-control {
      display: none;
    }
    .vjs-current-time {
      display: flex;
    }
    .vjs-picture-in-picture-control {
      display: none;
    }
    .vjs-fullscreen-control {
      display: none;
    }
    .vjs-live-control {
      display: none;
    }
    .vjs-big-play-button {
      display: none;
    }
    .vjs-playback-rate {
      display: none;
    }
    .vjs-chapters-button {
      display: none;
    }
    .vjs-descriptions-button {
      display: none;
    }
    .vjs-subs-caps-button {
      display: none;
    }
    .vjs-audio-button {
      display: none;
    }
  
    .vjs-control-bar {
      display: none;
    }
    .vjs-live-control {
      display: none;
    }
    .vjs-seek-to-live-control {
      display: none;
    }
  }
}

.debug-log-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999999;
  padding: 100px;
  color: #FFFFFF;
  font-size: 30px;
  display: flex;
  flex-direction: column;

  .measured-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;

    .measured-item-label {
    }

    .measured-item-value {
      padding-left: 30px;
    }
  }
}


video::cue  {
  font-size: 8px !important;
}
video {
  .fingerprint {
    &::cue  {
      font-size: 8px !important;
    }
    font-size: 8px !important;
  }
}