.top-right {
  position: absolute;
  top: 12px;
  right: 18px;
  display: flex;
}

.vip-badge {
  width: 40px;
  height: 20px;
  border-radius: 3px;
  background-color: #ea5827;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  span {
    font-family: Roboto;
    font-size: 12px;
    color: #f2f2f2;
    text-decoration: none solid rgb(242, 242, 242);
    line-height: 21px;
    text-align: right;
  }
}
.img-wrapper{
  padding-top: calc(9 / 16 * 100%);
  position: relative;
  display: block;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .view-more{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #696969;
    display: none;
    justify-content: center;
    align-items: center;
    font-size: 38px;
    color: #fff;
    font-weight: 700;
    &.show {
      display: flex;
    }
  }
}


.top-ten-content {
  position: absolute;
  bottom: 8px;
  left: -25px;
  &#top-ten-content-10 {
    left: -15px;
  }
  &#top-ten-content-1 {
    left: -30px;
  }
  .ribbon-banner-image {
    width: 150px;
  }
}