@import '../../../constant.scss';

.content-wrapper {
  height: 100vh;
  background-size: 100% 100%;
  background-attachment: fixed;
  overflow-y: scroll;
  scrollbar-width: none;
  padding-left: 0 !important;

  .content-button-group {
    display: flex;
    flex-direction: column;

    .content-button-item {
      border: 3px solid transparent;
      display: block;
      float: left;
      padding-top: 15px;
      padding-bottom: 18px;
      border-radius: 3px;
      cursor: pointer;
      font-size: 25px;
      background: rgba(74,74,74, 0.7);
      .button-icon {
        color: #FFFFFF;
        font-size: 20px;
        margin-left: 20px;
        margin-top: -2px;
      }
  
      .button-text {
        color: #b6b6b6;
        margin-left: 10px;
        margin-left: 15px;
      }

      &.active {
        border: 1px solid #FFFFFF;
        background-color: #FFFFFF;
        opacity: 1;
        
        .button-text {
          color: #000000;
          margin-left: 15px;
          margin-top: 10px;
        }

        .button-icon {
          color: #000000;
          font-size: 20px;
          margin-left: 20px;
        }
      }

      &:hover {
        border: 1px solid #FFFFFF;
        background-color: #FFFFFF;
        opacity: 1;

        .button-text {
          color: #000000;
          margin-left: 30px;
        }

        .button-icon {
          color: #000000;
          font-size: 20px;
          margin-left: 20px;
        }
      }
    }
    .content-progress{
      margin-top: 16px;
      height: 5px;
      background-color: #dadada;
      padding: 0;
      .progress-bar{
        background-color: #c40012;
      }
    }
  }

  .content-landing-back-button {
    margin-top: 20px;
    margin-left: 20px;
  
    color: white;
    font-weight: bold;
    font-size: 40px;
  
    .content-landing-back-button-wrapper {
      width: 80px;
      height: 80px;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      border-radius: 40px;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  
      .content-landing-back-icon {
        cursor: pointer;
        color: white;
        margin-right: 5px; 
      }
  
      &.active {
        background-color: #D10A14;
      }
  
      &:hover {
        background-color: #D10A14;
      }
    }
  }

  .content-overlay {
    background: linear-gradient(to left, transparent 30%, black 90%);
    height: 100vh;
    padding-left: 0.75rem;
  }
  
  .content-row {
    padding-left: 50px;

    .content-meta-data {
      margin-bottom: 5px;
      span {
        font-size: 25px;
        color: #B6B6B6;
      }
    }
  }

  .content-landing-margin-top-button {
    margin-top: 5px;
  }

  .content-back-icon-container {
    position: fixed;
    top: 10px;
    font-size: 40px;
  }
  .content-title-container {
    padding-top: 110px;
  }

  .content-icons {
    padding-left: 50px;
    margin-bottom: 16px;

    display: flex;

    .vip-badge-icon {
      color: #ffd500;
      font-size: 16px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      img {
        margin-right: 6px;
        width: 20px;
        height: 20px;
      }
    }
  }

  .content-title {
    padding-left: 50px;
    color: white;
    font-weight: bold;
    font-size: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
    // text-shadow: 1px 1px #464646;
  }
  .content-divider {
    border: none;
    color: white;
    background-color: white;
    height: 1.5px;
    margin: 8px 0 8px 40px;
  }

  .content-info-view {
    span {
      color: white;
      font-size: 25px;
      font-weight: 400;

      .span-inner {
        color: #B6B6B6;
      }
    }
  }

  .content-description {
    color: #d6d6d6;
    padding-top: 28px;
    font-size: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
    text-shadow: 1px 1px #2E2E2E;
  }
  .content-actor-title {
    color: #808080;
    font-size: 35px;
    font-weight: bold;
  }
  .content-actor-list {
    color: #808080;
    font-size: 35px;
    margin-left: 5px;
  }
  .content-director-title {
    color: #808080;
    font-size: 25px;
    font-weight: bold;
  }

  .content-play-button {
    // display: inline-block;
    text-align: left;
    width: 250px;
    background-color: #363842;
    border: none;
    border-radius: 4px;
    color: white;
    padding: 12px 0 12px 40px;
    font-size: 16px;
    cursor: pointer;
    &.active {
      background-color: #D10A14;
    }
    &:hover {
      background-color: #D10A14;
    }
  }

  .content-upgrade-button {
    // display: inline-block;
    text-align: left;
    width: 350px;
    background-color: #363842;
    border: none;
    border-radius: 4px;
    color: white;
    padding: 12px 0 12px 40px;
    font-size: 16px;
    cursor: pointer;
    &.active {
      background-color: #ffe710;
      color: #363842;
    }
    &:hover {
      background-color: #ffe710;
      color: #363842;
    }
  }

  .content-upgrade-text {
    padding-left: 50px;
    font-size: 20px;
    color: white;

    &.active {
      color: #363842;
    }
    &:hover {
      color: #363842;
    }
  }

  .content-play-text {
    padding-left: 50px;
    font-size: 20px;
    color: white;
  }
  
  .content-back-icon {
    position: absolute;
    top: 45px;
    left: 50px;
    font-weight: bold;
    font-size: 30px;
    cursor: pointer;
    color: white;
    z-index: 10;
    &.active {
      color: #D10A14;
    }
    &:hover {
      color: #D10A14;
    }
  }
}
.content-wrapper::-webkit-scrollbar {
  display: none
}


.related-title {
  font-weight: bold;
  font-size: 30px;
  color: white;
}

.related-item {
  cursor: pointer;
  width: 100%;
  &.active {
    transform: scale(1.1);
    border: 5px solid white;
  }
  &:hover {
    transform: scale(1.1);
    border: 5px solid white;
  }

  img {
    width: 100%;
    aspect-ratio: $aspectRatioThumbnail;
  }
}

.content-detail-loading {
  background-image: linear-gradient(to bottom right, #004E73, #00193B, #491337) !important;
}

.content-background-color {
  background-image: linear-gradient(to bottom right, #004E73, #00193B, #491337) !important;
}

.content-back-instruction {
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 16px;
  color: white;
  z-index: 10;
  text-shadow: 0.5px 0.5px #A6A6A6;

  .content-button-shadow {
    text-shadow: 1px 1px #A6A6A6;
  }
}