.profile-title {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 40px;
  color: #ffffff;
  margin-top: 10vh;
}

.profile-body {
  margin-top: 6vh;
  font-size: 16px;
  color: #ffffff;
}

.profile-label {
  opacity: 0.7;
  font-size: 28px;
}

.profile-value {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 35px;
}

.service-package {
  border-color: #FFFFFF;
  border-width: 1px;
  border-style: solid;
  padding: 20px;
  border-radius: 10px;
  margin: 0;
  padding: 21px 50px 34px 25px;
  font-size: 15px;
  color: #ffffff
}

.profile-sub-description {
  color: #FFFFFF;
  font-size: 28px;
  font-style: italic;
  opacity: 0.9;
  margin-top: 28px;
}

.profile-sub-description-info {
  font-size: 24px;
  color: #ffffff;
  font-style: italic;
  opacity: 0.7;
}

.profile-fake-email-info {
  color: #FFFFFF;
  font-size: 13px;
  font-style: italic;
  opacity: 0.4;
  margin-top: 5px;
}

.profile-sub-none-info {
  color: #FFFFFF;
  font-size: 15px;
  font-style: italic;
  opacity: 0.7;
  margin-top: 28px;
}

.profile-modal-highlight-red {
  opacity: 1 !important;
  color: #D10A14;
}

.profile-modal-highlight-yellow {
  opacity: 1 !important;
  color: #FAD305;
}

.profile-modal-back-instruction {
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 16px;
  color: white;
  z-index: 10;
  text-shadow: 0.5px 0.5px #A6A6A6;

  .content-button-shadow {
    text-shadow: 1px 1px #A6A6A6;
  }
}

.profile-back-button {
  margin-top: 20px;
  margin-left: 20px;

  color: white;
  font-weight: bold;
  font-size: 40px;

  .profile-back-button-wrapper {
    width: 80px;
    height: 80px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 40px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;

    .profile-back-icon {
      cursor: pointer;
      color: white;
      margin-right: 5px; 
    }

    &.active {
      background-color: #D10A14;
    }

    &:hover {
      background-color: #D10A14;
    }
  }
}

.profile-info-left {
  width: 43%;
  &-container{
    max-width: 510px;
  }
}

.profile-info-right {
  width: 57%;
}

.profile-btn {
  width:100%;
  font-size: 15px!important;
  color: #ffffff;
  background: #d10a14;
  height: 40px;
  margin-top: 40px;
}
.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 190px 0;
}

.profile-logout-btn {
  background-color: #c3c3c3 !important;
  border: 0 !important;
  margin-top: 70px;
  height: 60px;
  font-size: 25px !important;
  color: #D10A14 !important;
  font-weight: bold !important;

  &.active {
    background-color: #D10A14 !important;
    color: #FFFFFF !important;
  }
  &:hover {
    background-color: #D10A14 !important;
    color: #FFFFFF !important;
  }
}