.vjs-cwl{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background-color: #1c1c1c;

  display: flex;
  justify-content:center;
  align-items:center;
}

.vjs-cwl-back{
  position: absolute;
  top: 46px;
  left: 29px;
  display: grid;
  .back {
    font-size: 52px;
    width:  52px;
    color: #fff;
    &.active {
      color: #D10A14;
    }
    &:hover {
      color: #D10A14;
    }
  }
}


.vjs-cwl-container{
  display: flex;
  justify-content:center;
  align-items:center;
  flex-direction: column;
  font-family: Open Sans;

  margin-bottom: 19px;
  width: 1079px;
  .title{
    font-size: 52px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
  }
  .description{
    font-family: Open Sans;
    font-size: 27px;
    color: #999999;
    text-decoration: none solid rgb(153, 153, 153);
    line-height: 38px;
    text-align: center;
    margin-bottom: 33px;
  }
  .vjs-cwl-btn{
    width: 280px;
    height: 75px;
    border-radius: 5px;
    background-color: #dadada;

    font-size: 30px;
    color: #333333;
    text-decoration: none solid rgb(51, 51, 51);
    line-height: 35px;
    text-align: center;

    padding: 20px 85px;
    &.active {
      background-color: #D10A14;
      color: #fff;
    }
    &:hover {
      background-color: #D10A14;
      color: #fff;
    }
  }
}