.modal-login-background {
  background-image: linear-gradient(to bottom right, #004E73, #00193B, #491337);
}

.login-container {
  margin: 8% auto 0 auto;
  height: 350px;

  .label {
    color: white;
    font-size: 30px;
  }

  button {
    width: 100%;
  }
}

.margin-top-login {
  margin-top: 3%;
}

.login-back-button {
  margin-top: 20px;
  margin-left: 20px;

  color: white;
  font-weight: bold;
  font-size: 40px;

  .login-back-button-wrapper {
    width: 80px;
    height: 80px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 40px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;

    .login-back-icon {
      cursor: pointer;
      color: white;
      margin-right: 5px;
    }

    &.active {
      background-color: #D10A14;
    }

    &:hover {
      background-color: #D10A14;
    }
  }
}

.login-back-icon {
  cursor: pointer;
  color: white;
  margin-right: 15px;
  &.active {
    color: #D10A14;
  }
  &:hover {
    color: #D10A14;
  }
}

.login-btn {
  background-color: #D10A14 !important;
  border: 0 !important;
  margin-top: 70px;
  height: 60px;
  font-size: 25px !important;
  &.active {
    border-color: #FFFFFF;
    border-width: 2px !important;
    border-style: solid !important;
  }
  &:hover {
    border-color: #FFFFFF;
    border-width: 2px !important;
    border-style: solid !important;
  }
}

.back-btn {
  background-color: #777777 !important;
  border: 0 !important;
  margin-top: 15px;

  &.active {
    border-color: #FFFFFF;
    border-width: 2px !important;
    border-style: solid !important;
  }
  &:hover {
    border-color: #FFFFFF;
    border-width: 2px !important;
    border-style: solid !important;
  }
}

.email-input {
  background-color: #A7A7A7 !important;
  cursor: pointer;
  height: 60px;
  font-size: 25px !important;
  &.active {
    background-color: #FFFFFF !important;
    border-color: #D10A14 !important;
    border-width: 4px !important;
    border-style: solid !important;
  }
  &:hover {
    background-color: #FFFFFF !important;
    border-color: #D10A14 !important;
    border-width: 4px !important;
    border-style: solid !important;
  }
}

.password-input {
  background-color: #A7A7A7 !important;
  cursor: pointer;
  height: 60px;
  font-size: 25px !important;
  &.active {
    background-color: #FFFFFF !important;
    border-color: #D10A14 !important;
    border-width: 4px !important;
    border-style: solid !important;
  }
  &:hover {
    background-color: #FFFFFF !important;
    border-color: #D10A14 !important;
    border-width: 4px !important;
    border-style: solid !important;
  }
}

.keyboard {
  margin-top: 25% !important;

  .login-alphabet-container {
    padding-left: 100px;
    padding-right: 100px;
  }
  
  .login-alphabet {
    cursor: pointer;
    border-top: 0px solid black;
    border-left: 0px solid black;
    color: white;
    font-size: 46px;
    text-align: center;
    &.active {
      // background-color: #D10A14;
      color: #D10A14;
    }
    &:hover {
      // background-color: #D10A14;
      color: #D10A14;
    }
  }

  .login-alphabet-action-wrapper {
    margin-top: 20px !important;
  }
  
  .login-alphabet-action {
    cursor: pointer;
    border: 0px solid black;
    color: white;
    font-size: 46px;
    text-align: center;
    &.active {
      // background-color: #D10A14;
      color: #D10A14;
    }
    &:hover {
      // background-color: #D10A14;
      color: #D10A14;
    }
  }
  
  .login-border-right {
    border-right: 0px solid black;
  }
  .login-border-bottom {
    border-bottom: 0px solid black;
  }
}

.error-text {
  color: red;
}

.login-title {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 40px;
  text-align: center;
  padding-bottom: 50px;
}

.login-modal-back-instruction {
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 16px;
  color: white;
  z-index: 10;
  text-shadow: 0.5px 0.5px #A6A6A6;

  .content-button-shadow {
    text-shadow: 1px 1px #A6A6A6;
  }
}

.login-modal-version{
  position: fixed;
  bottom: 16px;
  color: #fff;
  left: 80px;
  font-size: 16px;
}
