@import '../../../constant.scss';

.season-background {
  background-image: linear-gradient(to bottom right, #004E73, #00193B, #491337);
  min-height: 110vh;
  max-height: 110vh;
}

.modal-season-background {
  background-image: linear-gradient(to bottom right, #004E73, #00193B, #491337);
  background-color: rgba(0, 0, 0, 0.9) !important;
  min-height: 110vh;
  max-height: 110vh;
}

.season {
  max-height: 110vh;
  overflow-y: scroll;
  scrollbar-width: none;

  .season-container {
    padding-top: 100px;
  }

  .alert {
    cursor: pointer;
    background-color: #363841;
    font-size: 25px;
    font-weight: bold;
    color: white;
    &.active {
      background-color: #D10A14;
    }
    &:hover {
      background-color: #D10A14;
    }
  }

  .season-description {
    color: #FFFFFF;
    opacity: 0.6;
    margin-top: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7; /* number of lines to show */
    -webkit-box-orient: vertical;
    font-size: 25px;
  }

  .season-item {
    color: #FFFFFF;
    opacity: 0.6;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: 25px !important;
    padding-left: 20px;

    &.active {
      background-color: #FFFFFF;
      opacity: 1;
      padding-left: 20px;
      color: #000000;
    }
    &:hover {
      background-color: #FFFFFF;
      opacity: 1;
      padding-left: 20px;
      color: #000000;
    }
  }
}
.season::-webkit-scrollbar {
  display: none
}

.modal::-webkit-scrollbar {
  display: none
}
.modal-content::-webkit-scrollbar {
  display: none
}
.modal-body::-webkit-scrollbar {
  display: none
}

.container-episode-title {
  max-height: 80vh;
  overflow-y: scroll;
  scrollbar-width: none;
  margin-top: 70px;
}
.container-episode-title::-webkit-scrollbar {
  display: none
}

.container-episode {
  max-height: 80vh;
  overflow-y: scroll;
  scrollbar-width: none;
}
.container-episode::-webkit-scrollbar {
  display: none
}


.season-back-icon {
  position: absolute;
  top: 45px;
  left: 50px;
  font-weight: bold;
  font-size: 30px;
  cursor: pointer;
  color: white;
  z-index: 10;
  &.active {
    color: #D10A14;
  }
  &:hover {
    color: #D10A14;
  }
}

.season-title {
  color: white;
  font-size: 40px;
  overflow: hidden;
  font-weight: bold;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.season-row {
  padding-left: 15px;
  .season-year {
    color: white;
    font-size: 28px;
    opacity: 0.7;
  }
  .season-view {
    color: white;
    font-size: 20px;
  }
  .season-favourite {
    color: white;
    font-size: 20px;
  }
}


.episode {
  cursor: pointer;
  opacity: 0.5;

  &.active {
    opacity: 1;
  }
  &:hover {
    opacity: 1;
  }

  .episode-item {
    width: 100%;

    img {
      width: 100%;
      aspect-ratio: $aspectRatioThumbnail;
    }
  }
  .episode-info {
    position: relative;

    .episode-title {
      font-size: 28px;
      font-weight: bold;
      color: white;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
    .episode-views {
      position: absolute;
      bottom: 0;
      left: 20px;
      font-size: 18px;
      font-weight: bold;
      color: white;
    }
    .episode-favorites {
      position: absolute;
      bottom: 0;
      right: 20px;
      font-size: 18px;
      font-weight: bold;
      color: white;
    }
  }
}

.season-item-wrapper {
  position: relative;
}

.season-back-instruction {
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 16px;
  color: white;
  z-index: 10;
  text-shadow: 0.5px 0.5px #A6A6A6;

  .content-button-shadow {
    text-shadow: 1px 1px #A6A6A6;
  }
}