.loading-overlay{
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background: rgba(1,1,1, 0.5);
  display: block;
  &.hide {
    display: none;
  }
}