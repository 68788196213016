
.modal-forced-logout {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.8);
}
.modal-dialog {
  pointer-events: unset;
}
.modal-forced-logout-container {
  background-image: linear-gradient(to bottom right, #004E73, #00193B, #491337);
  margin: 0 auto;
  border-radius: 10px;
  padding: 91px 54px 77px ;
  max-width: 890px;
  min-height: 540px;
  height: 100%;
  border-radius: 20px;
  min-width: 1138px;
  min-height: 492px;
}

.modal-forced-logout-background {
  // background-color: rgba(0, 0, 0, 0.8);
}

.modal-forced-logout-header{
  font-family: Roboto;
  margin-bottom: 23px;
  .title {
    font-size: 60px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    font-weight: bold;
  }
}

.modal-forced-logout-body{
  font-family: Roboto;
  margin-bottom: 43px;
  .description {
    font-size: 34px;
    color: #f2f2f2;
    text-decoration: none solid rgb(242, 242, 242);
    line-height: 55px;
    text-align: center;
  }
}
.modal-forced-logout-btn{
  width: 250px;
  height: 76px;
  border-radius: 6px;
  background-color: #c40012;
  
  font-size: 31px;
  color: #f2f2f2;
  text-decoration: none solid rgb(242, 242, 242);
  line-height: 50px;
  text-align: center;
  cursor: pointer;

  &:hover{
    color: #f2f2f2;
  }
}

