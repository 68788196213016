.menu {
  // border-right: 0.5px solid #484848;

  .menu-app-logo-sctv {
    height: 50px;
    position: absolute;
    top: 5vh;
    margin-left: 20px;
  }
}

.sidebar-wrapper {
  .nav-item {
    padding: 5px;
    height: 66px;
    cursor: pointer;
    margin-bottom: 10px;

    .nav-link {
      padding-right: 15px;
      flex-direction: row;
      display: flex;
      
      span {
        margin-top: 5px;
      }
    }

    .active {
      background-color: transparent !important;

      span {
        color: #D10A14 !important;
        font-weight: bold !important;
      }
    }

    .nav-item-active {
      display: block;
      position: absolute;
      width: 5px;
      height: 51px;
      background-color: #D10A14;
    }

    .sidebar-img {
      margin-left: 15px;
      margin-right: 15px;
      margin-top: 12px;
    }

    a {
      &:hover {
        color: #D10A14 !important;

        span {
          color: #D10A14 !important;
          font-weight: bold !important;
        }
      }
    }
  }

  .sidebar-text {
    // padding: 0 20px 0 10px;
    font-size: 32px;
    line-height: 40px;
    color: white;
    padding-right: 20px;
  }
}

.menu-scroll {
  max-height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none;
  padding-top: 10vh;
}

.menu-scroll::-webkit-scrollbar {
  display: none
}

.menu-logo-wrap {
  position: absolute;
}

.menu-logo-center {
  margin: 0 auto;

  .menu-logo {
    margin-left: 0px;
    margin-right: 5px;
    // margin-top: 0.8rem !important;
  }
}

.menu-scroll-overlay {
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none;
  position: absolute;
  width: 100%;
  // background: linear-gradient(to left, transparent 30%, #004E73 80%);
  background: linear-gradient(to left, transparent 30%, #212121 80%);

  z-index: 35;
  left: -0.5rem !important;
  padding-left: 1.5rem;

  .menu-margin-top {
    margin-top: 16vh;
  }
}

.menu-scroll-remove-padding-left {
  margin-left: 0.5rem !important;
}

.menu-margin-top {
  margin-top: 16vh;
}