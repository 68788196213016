@import '../../../constant.scss';

.modal-content {
  .modal-epg-background {
    background-image: linear-gradient(to bottom right, #004E73, #00193B, #491337);
  }

  .modal-body {
    background-color: rgba(0, 0, 0, 0.7);

    .toast {
      z-index: 50;
      position: absolute;
      bottom: 40%;
      left: 0;
      right: 0;
      margin: 0 auto;
      padding: 5px 10px;
      background-color: grey;
      color: white;
      font-size: 20px;
      font-weight: bold;
    }

    .alert {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      background-color: transparent;
      font-weight: bold;
      color: white;
      border-color: rgb(177, 177, 177);
      font-family: Roboto;
      font-size: 25px;
      text-decoration: none solid rgb(0, 0, 0);
      line-height: 21px;
      height: 67px;
      padding: 0 18px 0 26px;
      &.focus {
        background-color: rgb(177, 177, 177);
        color: #000000;
      }

      &.active {
        background-color: #FFFF;
        color: #000000;
      }

      &:hover {
        background-color: #FFFF;
        color: #000000;
      }
    }

    // .epg-modal-back-button {
    //   position: absolute;
    //   top: 20px;
    //   left: 20px;
    // }

    .epg-title {
      color: white;
      font-weight: bold;
      font-size: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      -webkit-box-orient: vertical;
      margin-top: 30px;
      margin-left: 30px;

      .epg-back-icon {
        cursor: pointer;
        color: white;
        margin-right: 25px;
  
        &.active {
          color: #D10A14;
        }
        &:hover {
          color: #D10A14;
        }
      }
    }

    .epg-title-icon {
      margin-left: 15px;
    }

    .container-schedule {
      max-height: 80vh;
      overflow-y: scroll;
      scrollbar-width: none;
      margin-left: 30px;
      margin-top: 50px;
      min-width:  548px;
    }
    .container-schedule::-webkit-scrollbar {
      display: none
    }

    .container-epg {
      max-height: 80vh;
      overflow-y: scroll;
      scrollbar-width: none;
      margin-top: 40px;
      margin-left: 100px;

      .container-epg-scroll {
        max-height: 80vh;
        overflow-y: scroll;
        scrollbar-width: none;

        .fix-epg-scroll-to-bottom {
          height: 50px;
          width: '100%';
        }
      }

      .container-epg-scroll::-webkit-scrollbar {
        display: none
      }

      .epg-schedule-title {
        color: white;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px;
      }

      .epg {
        cursor: pointer;
        opacity: 0.5;
        &.active {
          opacity: 1;
          font-weight: bold;
        }
        &:hover {
          opacity: 1;
        }

        .epg-info {
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;

          .epg-info-item-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 30px;

            .epg-info-title-group {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-left: 50px;

              .epg-info-favorites {
                font-size: 18px;
                font-weight: bold;
                color: red;
                // margin-top: -5px;
              }

              .epg-info-title {
                font-size: 40px;
                // font-weight: bold;
                color: white;

                &.blackout {
                  color: rgb(91, 91, 91);
                }
              }
            }


            .epg-info-time {
              font-size: 25px;
              // font-weight: bold;
              color: white;
            }
          }
        }
      }
    }

    .container-epg::-webkit-scrollbar {
      display: none
    }
  }
}

.epg-live-text {
  font-family: Roboto;
  font-size: 25px;
  color: #fc3831;
  text-decoration: none solid rgb(252, 56, 49);
  line-height: 21px;
  display: flex;
  align-items: center;
}

.epg-live-icon {
  font-size: 10px;
  margin-right: 8px;
}

.epg-item {
  img {
    width: 100%;
    aspect-ratio: $aspectRatioThumbnail;
  }
}