.player-epg-col {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.player-epg-back-icon {
  position: absolute;
  top: 45px;
  left: 40px;
  font-weight: bold;
  font-size: 35px;
  cursor: pointer;
  color: white;
  z-index: 10;
  &.active {
    color: #D10A14;
  }
  &:hover {
    color: #D10A14;
  }
}

.player-epg-schedule-icon {
  cursor: pointer;
  position: absolute;
  top: 45px;
  left: 110px;
  font-weight: bold;
  font-size: 35px;
  cursor: pointer;
  color: white;
  z-index: 10;
  &.active {
    color: #D10A14;
  }
  &:hover {
    color: #D10A14;
  }
}

.player-epg-reset-live {
  cursor: pointer;
  position: absolute;
  top: 45px;
  left: 190px;
  font-weight: bold;
  font-size: 35px;
  cursor: pointer;
  color: white;
  z-index: 10;
  &.active {
    color: #D10A14;
  }
  &:hover {
    color: #D10A14;
  }
}

.player-epg-title {
  position: absolute;
  top: 30px;
  right: 40px;
  // font-weight: bold;
  font-size: 35px;
  color: white;
  z-index: 10;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.player-epg-schedule {
  cursor: pointer;
  position: absolute;
  bottom: 4%;
  left: 70px;
  z-index: 10;
  padding: 5px 20px;
  font-weight: bold;
  font-size: 25px;
  color: white;
  &.active {
    background-color: #D10A14;
  }
  &:hover {
    background-color: #D10A14;
  }
}

.player-epg-icon-warp {
  position: absolute;
  top: 47px;
  left: 270px;
  z-index: 10;
  border-radius: 50px;
  background-color: #0078FC;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  padding-right: 15px;
  padding-bottom: 8px;
  padding-left: 15px;

  .player-live-icon-white-dot {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #FFFFFF;
  }

  .player-live-icon-text {
    display: block;
    float: left;
    color: #FFFFFF;
    margin-left: 10px;
    font-size: 13px;
  }
}

.player-epg {
  .toast {
    z-index: 500;
    width: 500px !important;
    position: absolute;
    bottom: 5%;
    right: 0;
    padding: 5px 10px;
    background-color: grey;
    color: white;
    font-size: 20px;
    font-weight: bold;
  }
  
  .video-js {
    .vjs-volume-panel {
      display: none;
    }
    .vjs-picture-in-picture-control {
      display: none;
    }
    .vjs-fullscreen-control {
      display: none;
    }
    .vjs-live-control {
      display: none;
    }
    .vjs-seek-to-live-control {
      display: none;
    }
    .vjs-big-play-button {
      display: none;
    }
    .vjs-playback-rate {
      display: none;
    }
    .vjs-chapters-button {
      display: none;
    }
    .vjs-descriptions-button {
      display: none;
    }
    .vjs-subs-caps-button {
      display: none;
    }
    .vjs-audio-button {
      display: none;
    }
    .vjs-progress-control {
      .vjs-progress-holder {
        .vjs-play-progress {
          background-color: #D10A14;
        }
        .vjs-play-progress:before {
          display: none;
        }
      }
    }

    .vjs-slider {
      height: 10px;
      border-radius: 10px;
      overflow: hidden;
    }

    .vjs-control-bar {
      position: absolute;
      bottom: 12%;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 90%;
      background-color: transparent;
      z-index: 10;
      touch-action: none;
      align-items: center;

      .vjs-volume-panel {
        display: none;
      }
    }

    .vjs-play-control {
      width: 60px;
      height: 60px;
      border-radius: 30px !important;

      &.active {
        background-color: #D10A14;
        border-radius: 20px;
      }

      .vjs-icon-placeholder::before {
        font-size: 3.5em;
      }
    }
    .vjs-current-time {
      display: flex;
      align-items: center;

      .vjs-current-time-display {
        font-size: 28px;
      }
    }

    .vjs-remaining-time {
      display: flex;
      align-items: center;

      .vjs-remaining-time-display {
        font-size: 28px;
      }
    }
  }

  .vjs-tech {
    pointer-events: none;
  }
}