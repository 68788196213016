.age-rating-badge {
  width: 20px;
  height: 20px;
  margin-right: 6px;
  .age-rating-badge-image {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    display: block;
  }
  &.banner {
    position: absolute;
    top: 29px;
    left: 47px;
    width: 30px;
    height: 30px;
    .age-rating-badge-image {
      width: 100%;
      height: 100%;
      aspect-ratio: 1;
    }
  }
  &.content {
    width: 30px;
    height: 30px;
  }
  &.player {
    width: 40px;
    height: 40px;
    z-index: 9;
  }
}

.live-badge {
  padding: 0 7px;
  min-width: 53px;
  height: 20px;
  border-radius: 20px;
  background-color: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #696969;
  margin-right: 2px;

  &.active {
    color: #e72d30;
  }
  svg {
    font-size: 9px;
    margin-right: 5px;
  }
  span {
    height: 17px;
    font-family: Open Sans;
    font-size: 12px;
    text-transform: uppercase;
    text-align: right;
    font-weight: bold;
  }
}


