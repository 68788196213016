.ribbon-new-badge {
  position: absolute;
  bottom: 30px;
  right: 15px;
  z-index: 9;
  background-color: #E72E2E;
  font-size: 22px;
  color: #FFFFFF;
  padding: 1px;
  padding-left: 17px;
  padding-right: 17px;
  font-weight: bold;
}

.ribbon-new-badge-viewmore {
  position: absolute;
  bottom: 30px;
  right: 0px;
  z-index: 9;
  background-color: #E72E2E;
  font-size: 22px;
  color: #FFFFFF;
  padding: 1px;
  padding-left: 17px;
  padding-right: 17px;
  font-weight: bold;
}