.modal-warning-login-background {
  background-color: rgba(0, 0, 0, 0.8);
}

.warning-login-modal-text {
  font-size: 40px;
  font-weight: bold;
  color: white;
  text-align: center;
}

.modal-content-warning-login {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.warning-login-modal-container {
  background-image: linear-gradient(to bottom right, #004E73, #00193B, #491337);
  margin: 0 auto;
  border-radius: 10px;
  padding: 81px 105px 77px 105px;
  margin-top: 27vh;
  max-width: 890px;
  min-height: 540px;
  height: 100%;
  border-radius: 20px;
}

.warning-login-premium {
  padding: 123px 193px 75px 193px;
  max-width: 1414px;
  min-height: 430px;
  .warning-login-wrap-btn {
    margin-top: 60px;
  }
}

.warning-login-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100%;
}


.warning-login-button {
  width: 201px;
  height: 76px;
  border-radius: 6px;
  background: none;
  font-size: 31px;
  color: #f2f2f2;
  border: 2px solid #ffffff;
  margin: 0 16.5px 0 16.5px;
  &.warning-login-button-active {
    background: #c40012;
    border: 2px solid #c40012;
  }
}

.warning-login-button-description {
  font-size: 34px;
  color: #f2f2f2;
  line-height: 55px;
  text-align: center;
}

.warning-login-highlight-text {
  color: #c40012;
  font-weight: bold;
}


.warning-login-wrap-btn {
  display: flex;
}
.warning-login-img{
  max-width: 183px;
  max-height: 183px;
  width: 100%;
  height: 100%;
  margin-bottom: 32px;
}
.warning-login-title {
  font-size: 34px;
  color: #f2f2f2;
  text-decoration: none solid rgb(242, 242, 242);
  line-height: 55px;
  margin-bottom: 36px;
}