@import "~bootstrap/scss/bootstrap";


body {
  background-color: #000000;
  // background: linear-gradient(to bottom right, #004E73, #00193B, #491337) !important;
  overflow: hidden;
}

.modal-body {
  padding: 0;
}


.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.spinner-ui {
  display: block;
  position:fixed;
  top: 50%;
  right: 46%;
  width: 5rem;
  height: 5rem;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.figure-img {
  margin-bottom: 0 !important;
}

.display-none {
  display: none;
}

.display-show {
  display: block;
}

.visibility-hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
}

.visibility-visible {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
}