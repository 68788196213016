@import '../../../constant.scss';

.ribbon {
    position: absolute;
    left: 130px;
    top: 0;
    z-index: 30;
    max-height: 100vh;
    overflow-y: scroll;
    scrollbar-width: none;
}
.ribbon::-webkit-scrollbar {
    display: none
}

.ribbon-title {
    color: white;
    font-size: 32px;
    font-weight: bold;
    padding-left: 8px;
}

.ribbon-item-container {
    overflow-x: scroll;
    padding: 15px 0;
    scrollbar-width: none;
    scroll-behavior: smooth;
}
.ribbon-item-container::-webkit-scrollbar {
    display: none
}

.ribbon-item {
    cursor: pointer;
    width: 100%;
    background-color: transparent;
    border-radius: 8px;
    border: 8px solid transparent;

    .content-ribbon-hover {
        position: absolute;
        display: none;
        z-index: 9999;
        width: 400px;
        height: 400px;
        background-color: #000000;
        border-radius: 8px;
        overflow: hidden !important;

        img {
            border-radius: 6px !important;
        }
    }

    &.active {
        // transform: scale(1, 1.1);
        // border: 8px solid #D10A14;

        &:before {
            content: "";
            border: 8px solid #D10A14;
            position: absolute;
            width: calc(100% - 8px);
            height: calc(100% - 8px);
            top: -3px;
            left: 4px;
            border-radius: 16px;
            z-index: 9;
        }

        .content-ribbon-hover {
            display: inline;
        }
    }

    &:hover {
        // transform: scale(1, 1.1);
        // border: 8px solid #D10A14;

        &:before {
            content: "";
            border: 8px solid #D10A14;
            position: absolute;
            width: calc(100% - 8px);
            height: calc(100% - 8px);
            top: -3px;
            left: 4px;
            border-radius: 16px;
            z-index: 9;
        }

        .content-ribbon-hover {
            display: inline;
        }
    }

    img.figure-img  {
        width: 100%;
        aspect-ratio: $aspectRatioThumbnail;
        border-radius: 6px !important;
    }

    .image-viewmore {
        width: 100%;
        aspect-ratio: $aspectRatioThumbnail;
    }
}

.ribbon-ribbon {
    padding-top: 10px;

    .ribbon-container-row {
        margin-top: 10px;
    }
}

.ribbon-banner {
    margin-right: 12px;
    margin-bottom: 20px;
    border: 8px solid transparent;
    margin-top: -8px;

    .carousel-inner {
        cursor: pointer;
        // height: 300px;
        // aspect-ratio: $aspectRatioBanner;
        border-radius: 0 0 4px 4px;

        .banner-button-watch {
            width: 250px;
            height: 70px;
            border-radius: 4px;
            position: absolute;
            z-index: 2;
            // top: 280px;
            bottom: 60px;
            left: 60px;
            background-color: rgba(255, 255, 255, 0.7);
            font-size: 30px;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            display: flex;
            // transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;

            &.active {
                // transform: scale(1);
                // border: 5px solid #D10A14;
                background-color: #D10A14;

                span {
                    font-size: 30px;
                    color: #FFFFFF;
                    // font-weight: bold;
                }
            }
            &:hover {
                // transform: scale(1);
                // border: 5px solid #D10A14;
                background-color: #D10A14;

                span {
                    font-size: 30px;
                    color: #FFFFFF;
                    // font-weight: bold;
                }
            }

            span {
                font-size: 30px;
                color: #121212;
                margin-left: 15px;
                // transition: color 0.15s ease-in-out;
            }
        }
    }
    // .carousel-control-prev, .carousel-control-next {
    //     width: auto;
    // }
    .carousel-item {
        width: 100%;
        // height: 300px;

        .ribbon-banner-info {
            position: absolute;
            // width: 50%;
            width: 100%;
            height: 100%;
            padding-top: 100px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(to left, transparent 30%, black 90%);
            background: -webkit-linear-gradient(to left, transparent 30%, black 90%);
            background: -o-linear-gradient(to left, transparent 30%, black 90%);
            background: -moz-linear-gradient(to left, transparent 30%, black 90%);

            .title {
                padding: 30px 0 0 60px;
                font-weight: bold;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* number of lines to show */
                -webkit-box-orient: vertical;

                color: #fafafa;
                font-size: 60px;
                line-height: 83px;
            }
            .span-inner {
                color: #d9d9d9;
            }
            .year {
                padding: 10px 0 0 60px;
                color: white;
                font-size: 30px;
            }
            .view {
                color: white;
                font-size: 20px;
            }
            .favourite {
                color: white;
                font-size: 20px;
            }
            .description {
                padding: 30px 0 0 60px;
                width: 50%;
                color: white;
                font-size: 30px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3; /* number of lines to show */
                -webkit-box-orient: vertical;
                text-shadow: 1px 1px #464646;
            }
        }
        .ribbon-banner-image-wrapper {
            position: relative;
            padding-top: calc(600 / 1920 * 100%);
        }
        .ribbon-banner-image {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            object-fit: cover;
            // aspect-ratio: $aspectRatioBanner;
            // height: 300px;
            // max-width: 100%;
            // max-height: 100%;
        }
    }
}

.ribbon-item-wrapper {
    position: relative;
}

.remote-js-debug-id {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9;
    font-size: 30px;
    color: #FFFFFF;
    background-color: #000000;
}

.ribbon-wrap-img {
    padding-top: calc(9 / 16 * 100%);
    position: relative;
    display: block;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}