.age-rating{
  display: flex;

  position: absolute;
  top: 98px;
  left: 6px;
  &.hide {
    display: none;
  }
  &.active{
    top: 3px;
  }
  .warning {
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.9);

    display: flex;
    align-items: center;
    max-width: 720px;
    min-height: 119px;

    border-radius: 7px;
    margin-left: 20px;
  }
  .wrap-title {
    display: flex;
    align-items: center;
  }
  .left {
    margin-left: 20px;
    margin-right: 18px;

    padding: 8px;
    .fa-exclamation-circle {
      font-size: 80px;
      color: #fff;
    }
  }
  .title {
    font-family: Noto Sans;
    font-size: 30px;
    color: #d60000;
    text-decoration: none solid rgb(214, 0, 0);
    line-height: 21px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 12px;
  }
  .description {
    font-family: Noto Sans;
    font-size: 15px;
    color: #b5b5b5;
    text-decoration: none solid rgb(181, 181, 181);
    line-height: 25px;
    margin: 0;
  }
}