.vjs-infomation{
  position: absolute;
  top: 0;

  width: 598px;
  height: calc(100vh - 50px);
  border-radius: 10px;
  background-color: #222222;
  box-shadow: 0px 1px 30px rgba(0,0,0,0.3);
  padding: 57px 42px;

  font-family: Roboto;
  transition: 0.5s all ease;
  margin: 25px 25px 25px 0;
  

  &.open {
    right: 0px;
    opacity: 1;
    visibility: visible;
  }
  &.close {
    right: -598px;
    opacity: 0;
    visibility: hidden;
  }
}

.vjs-infomation-type{
  font-size: 30px;
  color: #7d7d7d;
  text-decoration: none solid rgb(125, 125, 125);
  margin-bottom: 8px;
}

.vjs-infomation-title{
  font-size: 35px;
  color: #f7f4f3;
  text-decoration: none solid rgb(247, 244, 243);
  line-height: 56px;
  text-shadow: 1px 2px 3px rgba(0,0,0,0.5);
  margin-bottom: 26px;;
}

.vjs-infomation-description{
  font-size: 20px;
  color: #ffffff;
  text-decoration: none solid rgb(255, 255, 255);
  line-height: 34px;
  text-align: justify;

  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}