.live-event-after{  
  font-family: Roboto;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1c1c1c;
  font-family: Roboto;
  text-align: center;

  .live-event-back{
    position: absolute;
    top: 46px;
    left: 29px;
    display: grid;
    .back {
      font-size: 52px;
      width:  52px;
      color: #fff;
    }
  }

  .title {
    font-size: 60px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    margin-bottom: 8px;
    text-transform: uppercase;
    font-weight: bold;
  }
  .content-name {
    font-size: 40px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    // text-transform: uppercase;
    margin-bottom: 28px;
  }
  .txt {
    font-size: 45px;
    color: #b8b8b8;
    text-decoration: none solid rgb(184, 184, 184);
  }
  .time {
    font-size: 30px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
  }
  .rewatch {
    background-color: #e72d30;
    font-family: Noto Sans;
    font-size: 24px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    margin-top: 20px;
    border-radius: 8px;
    padding: 11px 22px;
    text-transform: uppercase;
  }
}
