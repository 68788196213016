@import '../../../constant.scss';

.viewmore {
  padding: 25px;
  max-height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none;
}

.viewmore::-webkit-scrollbar {
  display: none
}

.viewmore-bg-color {
  background-image: linear-gradient(to bottom right, #004E73, #00193B, #491337) !important;
}

.viewmore-back-icon {
  cursor: pointer;
  color: white;
  margin-right: 15px;
  &.active {
    color: #D10A14;
  }
  &:hover {
    color: #D10A14;
  }
}

.viewmore-title {
  color: white;
  font-weight: bold;
  font-size: 40px;
}

.viewmore-item {
  cursor: pointer;
  width: 100%;
  background-color: #181818;
  border-radius: 5px;
  border-color: #484848;
  border-width: 0px;
  border-style: solid;

  .viewmore-item-image {
    position: relative;
  }

  .gradient-bottom {
    display: none;
    position: absolute;
    width: 100%;
    height: 60px;
    background-image: linear-gradient(0deg, #181818,  transparent);
    bottom: -1px;
    z-index: 8;
  }

  .viewmore-content-description {
    display: none;
    color: #FFFFFF;
    padding: 10px;
    font-size: 12px;

    .viewmore-content-timeDetail {
      color: #D10A14;
      font-weight: bold;
    }

    .viewmore-content-description-row {
      margin-top: 5px;
    }

    .viewmore-content-description-label {
      color: #FAD305;
      font-size: 11px;
    }

    .viewmore-content-description-value {
      color: #919191;
      font-size: 11px;
    }
  }

  &.active {
    &:before {
      content: "";
      border: 8px solid #D10A14;
      position: absolute;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      top: -12px;
      left: -4px;
      border-radius: 16px;
      z-index: 9;
    }
  }
  
  &:hover {
    &:before {
      content: "";
      border: 8px solid #D10A14;
      position: absolute;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      top: -12px;
      left: -3px;
      border-radius: 16px;
      z-index: 9;
    }
  }

  img.figure-img {
    width: 100%;
    aspect-ratio: $aspectRatioThumbnail;
    border-radius: 8px !important;
  }
}

.viewmore-wrapper {
  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: grey;
    opacity: .5;
  }
}

.viewmore-item-wrapper {
  position: relative;
}