.splash-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  // background: url('./static/media/logo_splashscreen.png') no-repeat center center fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}