.exit-modal-text {
  font-size: 40px;
  font-weight: bold;
  color: white;
  text-align: center;
}

.exit-button-container {
  margin-top: 40px;
}

.exit-modal-container {
  margin-top: 40vh;
}
.exit-button {
  padding: 15px 25px;
  width: 250px;
  height: 60px;
  color: white !important;
  font-weight: bold !important;
  font-size: 25px !important;
  background-color: #363842 !important;
}

.exit-button-bg {
  background-color: #363842 !important;
}
.exit-button-bg-active {
  background-color: #D10A14 !important;
}