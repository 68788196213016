.ribbon-vip-badge {
  position: absolute;
  top: 15px;
  right: 25px;
  z-index: 9;
  background-color: #EA5827;
  font-size: 20px;
  color: #FFFFFF;
  padding: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  font-weight: bold;
}

.ribbon-vip-badge-viewmore {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 9;
  background-color: #EA5827;
  font-size: 25px;
  color: #FFFFFF;
  padding: 1px;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 3px;
  font-weight: bold;
}