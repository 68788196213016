@import '../../../constant.scss';

.search {
  position: absolute;
  left: 130px;
  top: 0;
  z-index: 30;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none;
  padding-top: 50px;
  .form-control {
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    font-size: 35px;
    font-weight: bold;
    border: none;
    height: 80px;
    padding-left: 30px;
  }
  .search-info-text {
    font-weight: bold;
    font-size: 30px;
    color: white;
    text-align: center;
    padding-top: 60px;
  }
}
.search::-webkit-scrollbar {
  display: none
}

.search-alphabet-container {
  padding-left: 13px;
  padding-right: 13px;
  border-radius: 8px;
}

.search-alphabet {
  cursor: pointer;
  // border-top: 1px solid black;
  // border-left: 1px solid black;
  color: white;
  font-size: 36px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.1);
  &.active {
    // background-color: #D10A14;
    color: #D10A14;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
  }
  &:hover {
    // background-color: #D10A14;
    color: #D10A14;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
  }
}

.search-alphabet-action {
  cursor: pointer;
  // border: 1px solid black;
  color: white;
  font-size: 36px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.1);
  &.active {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    // color: #D10A14;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    // color: #D10A14;
  }
}

.search-border-right {
  // border-right: 1px solid black;
}
.search-border-bottom {
  // border-bottom: 1px solid black;
}

.search-result-item {
  cursor: pointer;
  width: 100%;
  &.active {
    // transform: scale(1.1);
    // border: 5px solid #D10A14;

    &:before {
      content: "";
      border: 8px solid #D10A14;
      position: absolute;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      top: -12px;
      left: -4px;
      border-radius: 16px;
      z-index: 9;
    }
  }
  &:hover {
    // transform: scale(1.1);
    // border: 5px solid #D10A14;

    &:before {
      content: "";
      border: 8px solid #D10A14;
      position: absolute;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      top: -12px;
      left: -4px;
      border-radius: 16px;
      z-index: 9;
    }
  }

  img.figure-img {
    width: 100%;
    aspect-ratio: $aspectRatioThumbnail;
  }
}

.search-item-wrapper {
  position: relative;
}