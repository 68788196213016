.modal-subscription-background {
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-self: center;
  align-items: center;
}

.subscription-modal-text {
  font-size: 40px;
  font-weight: bold;
  color: white;
  text-align: center;
}

.modal-content-subscription {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.subscription-modal-container {
  background-image: linear-gradient(to bottom right, #004E73, #00193B, #491337);
  margin: 0 auto;
  padding: 103px 153px 75px 153px;
  width: 70%;
  max-width: 1614px;
  height: auto;
  border-radius: 20px;
}
.subscription-button {
  padding: 15px 25px;
  width: 150px;
  color: white !important;
  font-weight: bold !important;
  font-size: 20px !important;
  background-color: #363842 !important;
}

.subscription-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.subscription-button-bg-active {
  width: 201px;
  height: 76px;
  border-radius: 6px;
  background: #c40012;
  font-size: 31px;
  color: #f2f2f2;
  border: none;
}

.subscription-button-description {
  font-size: 34px;
  color: #f2f2f2;
  line-height: 50px;
  text-align: center;
  margin-bottom: 60px;
}

.subscription-highlight-text {
  color: #c40012;
  font-weight: bold;
}