.device_memory_wrapper {
  position: absolute;
  z-index: 999999999;
  display: flex;
  flex-direction: column;
  top: 10px;
  left: 20px;
  color: #FFFFFF;

  .device_memory_row {
    display: flex;
    flex-direction: row;
  }

  .device_memory_item {
    margin-right: 15px;
    display: flex;
    flex-direction: row;
  }
}